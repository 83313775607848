import { gql } from '@apollo/client';
import { ACCOUNT_FRAGMENTS } from '@/fe-graphql';

export const SET_ACCOUNT_PROPERTY_PREFERENCES = gql`
  mutation setAccountPropertyPreferences(
    $accountId: ID!
    $accountPropertyId: ID!
    $moveOutMinYears: Int
    $moveOutMaxYears: Int
    $allowMessageRequests: Boolean
    $onboardingProgress: JSONObject!
    $onboardingDetails: JSONObject!
  ) {
    setAccountPropertyPreferences(
      accountPropertyId: $accountPropertyId
      preferences: {
        moveOutMinYears: $moveOutMinYears
        moveOutMaxYears: $moveOutMaxYears
        allowMessageRequests: $allowMessageRequests
      }
    ) {
      id
    }
    updateOnboardingProgress(
      accountId: $accountId
      onboardingProgress: $onboardingProgress
      onboardingDetails: $onboardingDetails
    ) {
      id
      ...OnboardingDetails
      ...OnboardingProgress
    }
  }
  ${ACCOUNT_FRAGMENTS.onboardingDetails}
  ${ACCOUNT_FRAGMENTS.onboardingProgress}
`;
