import { gql } from '@apollo/client';

export const CREATE_SETUP_INTENT = gql`
  mutation createSetupIntent {
    createSetupIntent {
      id
      clientSecret
    }
  }
`;
