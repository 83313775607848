import { gql } from '@apollo/client';

export const SET_PROPERTY_DESCRIPTION = gql`
  mutation setPropertyDescription(
    $accountPropertyId: ID!
    $description: String!
  ) {
    setPropertyDescription(
      accountPropertyId: $accountPropertyId
      description: $description
    ) {
      id
      propertyDescription
    }
  }
`;
