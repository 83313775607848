import { gql } from '@apollo/client';
import { ACCOUNT_FRAGMENTS } from '@/fe-graphql';

export const UPDATE_ONBOARDING_PROGRESS = gql`
  mutation updateOnboardingProgress(
    $accountId: ID!
    $onboardingProgress: JSONObject!
    $onboardingDetails: JSONObject!
  ) {
    updateOnboardingProgress(
      accountId: $accountId
      onboardingProgress: $onboardingProgress
      onboardingDetails: $onboardingDetails
    ) {
      id
      ...OnboardingDetails
      ...OnboardingProgress
      summary {
        id
        allowedBookmarkedPropertiesCount
        currentBookmarkedPropertiesCount
        allowedDreamHomesCount
        currentDreamHomesCount
        currentRatedPropertiesCount
      }
    }
  }
  ${ACCOUNT_FRAGMENTS.onboardingDetails}
  ${ACCOUNT_FRAGMENTS.onboardingProgress}
`;
