const createCORSRequest = (method, url) => {
  let xhr = new XMLHttpRequest();
  if ('withCredentials' in xhr) {
    xhr.open(method, url, true);
  } else {
    xhr = null;
  }
  return xhr;
};

/**
 * Use a CORS call to upload the given file to S3. Assumes the url
 * parameter has been signed and is accessible for upload.
 */
export const uploadToS3 = (file, signUrl, onprogress, onDone) => {
  const xhr = createCORSRequest('PUT', signUrl);
  xhr.onload = () => onDone();

  xhr.onerror = () => {
    throw new Error('XHR Error');
  };

  xhr.upload.onprogress = event => {
    if (event.lengthComputable) {
      const percentLoaded = Math.round((event.loaded / event.total) * 100);
      onprogress?.(percentLoaded);
    }
  };

  xhr.setRequestHeader('Content-Type', 'image/png');
  xhr.send(file);
};
