import { gql } from '@apollo/client';
import { ACCOUNT_FRAGMENTS } from '@/fe-graphql';

export const ACCOUNT_BY_ID = gql`
  query accountById($id: ID!) {
    accountById(id: $id) {
      id
      ...OnboardingDetails
      ...OnboardingProgress
      searchProfiles {
        id
      }
      summary {
        id
        allowedBookmarkedPropertiesCount
        currentBookmarkedPropertiesCount
        allowedDreamHomesCount
        currentDreamHomesCount
        currentRatedPropertiesCount
      }
    }
  }
  ${ACCOUNT_FRAGMENTS.onboardingDetails}
  ${ACCOUNT_FRAGMENTS.onboardingProgress}
`;
