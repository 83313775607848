import { gql } from '@apollo/client';

export const UPDATE_PROPERTY_IMAGE = gql`
  mutation updatePropertyImage(
    $accountPropertyId: ID!
    $image: AccountPropertyImageInput
  ) {
    updatePropertyImage(accountPropertyId: $accountPropertyId, image: $image) {
      id
      additionalImages {
        id
        imageUrl
        label
        category
        description
        userId
      }
    }
  }
`;
