import { gql } from '@apollo/client';

export const RELEASE_CLAIM_ON_PROPERTY = gql`
  mutation releaseClaimOnProperty($accountPropertyId: ID!, $deleteData: Boolean) {
    releaseClaimOnProperty(accountPropertyId: $accountPropertyId, deleteData: $deleteData) {
      id
      attributes
      claimantInfo
    }
  }
`;
