import { gql } from '@apollo/client';

export const SET_USER_SETTINGS = gql`
  mutation setUserSettings(
    $missedNotifications: Boolean
    $featureAndServiceUpdates: Boolean
    $promoAndSpecialOffers: Boolean
    $newPropertyMatches: Boolean
    $timezone: String
    $twelveHourFormat: Boolean
    $temperatureInCelsius: Boolean
    $changeTimezoneIgnored: Boolean
  ) {
    setUserSettings(
      preferences: {
        missedNotifications: $missedNotifications
        featureAndServiceUpdates: $featureAndServiceUpdates
        promoAndSpecialOffers: $promoAndSpecialOffers
        newPropertyMatches: $newPropertyMatches
      }
      localization: {
        timezone: $timezone
        changeTimezoneIgnored: $changeTimezoneIgnored
        twelveHourFormat: $twelveHourFormat
        temperatureInCelsius: $temperatureInCelsius
      }
    ) {
      emailPreferences {
        missedNotifications
        featureAndServiceUpdates
        promoAndSpecialOffers
        newPropertyMatches
      }
      localizationSettings {
        timezone
        changeTimezoneIgnored
        twelveHourFormat
        temperatureInCelsius
      }
    }
  }
`;
