import { gql } from '@apollo/client';

export const CLEAR_NOTIFICATION = gql`
  mutation clearNotification(
    $notificationId: String!
    $action: NOTIFICATION_ACTION
    $additionalData: JSONObject
  ) {
    clearNotification(
      notificationId: $notificationId
      action: $action
      additionalData: $additionalData
    ) {
      id
      shown
      cleared
    }
  }
`;
