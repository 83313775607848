import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Property, RatingsInput } from '@/fe-common/types/graphql-types';
import {
  ADD_PROPERTY_BOOKMARK,
  CREATE_PROPERTY_RATING,
  REMOVE_PROPERTY_BOOKMARK,
  SEND_COLLABORATION_INVITATION,
} from '@/fe-graphql/mutations';

import { PROPERTY_USER_DATA } from './gql';

export const usePropertyWithUserData = () => {
  const [propertyWithUserData, setPropertyWithUserData] = useState<Property | null>(null);

  const [propertyDataQuery] = useLazyQuery(PROPERTY_USER_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data) {
        setPropertyWithUserData(data.property);
      }
    },
  });

  const getPropertyWithUserData = (propertyAddressSlugId) => {
    if (propertyAddressSlugId) {
      propertyDataQuery({
        variables: {
          propertyAddressSlugId,
        },
      });
    } else {
      setPropertyWithUserData(null);
    }
  };

  const [addPropertyBookmarkMutation] = useMutation(ADD_PROPERTY_BOOKMARK);
  const [createPropertyRatingMutation] = useMutation(CREATE_PROPERTY_RATING);
  const [removePropertyBookmarkMutation] = useMutation(
    REMOVE_PROPERTY_BOOKMARK,
  );
  const [sendCollaborationInvitationMutation] = useMutation(
    SEND_COLLABORATION_INVITATION,
  );

  const addPropertyBookmark = propertyId => {
    addPropertyBookmarkMutation({
      variables: {
        propertyId,
      },
    });
  };

  const createPropertyRating = (propertyId: string, searchProfileId: string, ratings: RatingsInput) => {
    if (!propertyId || !ratings) {
      throw new Error(
        'Required params were not passed to createPropertyRating',
      );
    }

    return createPropertyRatingMutation({
      variables: {
        propertyId,
        searchProfileId,
        ratings,
      },
    });
  };

  const removePropertyBookmark = propertyId => {
    removePropertyBookmarkMutation({
      variables: {
        propertyId,
      },
    });
  };

  const sendCollaborationInvitation = async ({
    propertyId,
    emailAddress,
    collaborationLabel,
    isAccountScope = false,
  }) =>
    sendCollaborationInvitationMutation({
      variables: {
        propertyId,
        emailAddress,
        collaborationLabel,
        isAccountScope,
      },
    });

  return {
    propertyWithUserData,
    getPropertyWithUserData,
    addPropertyBookmark,
    createPropertyRating,
    removePropertyBookmark,
    sendCollaborationInvitation,
  };
};
