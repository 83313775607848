import { gql } from '@apollo/client';

export const ACCOUNT_FRAGMENTS = {
  onboardingDetails: gql`
    fragment OnboardingDetails on Account {
      id
      onboardingDetails {
        archStyle
        disallowPropertySelection
        buildingType
        googlePlaceId
        propertyId
        propertyName
        streetViewUrl
        satelliteViewUrl
        claimantLabel
        accountPropertyId
        moveOutMinYears
        moveOutMaxYears
        allowMessageRequests
        collaboratorEmail
        collaboratorLabel
        accountUserEmails
      }
    }
  `,

  onboardingProgress: gql`
    fragment OnboardingProgress on Account {
      id
      onboardingProgress {
        propertyRelationship
        currentProperty
        movingPlan
        specifyCollaborator
        architecturalStyle
        confirmCollaborator
        specifyAccountUsers
        confirmAccountUsers
        rateProperties
      }
    }
  `,
};
