import { gql } from '@apollo/client';

export const SET_CLAIMANT_LABEL = gql`
  mutation setClaimantLabel($accountPropertyId: ID!, $claimantLabel: String!) {
    setClaimantLabel(
      accountPropertyId: $accountPropertyId
      claimantLabel: $claimantLabel
    ) {
      id
      claimantInfo
    }
  }
`;
