import { gql } from '@apollo/client';

export const MY_ACCOUNT_PROPERTY = gql`
  fragment MyAccounProperty_PropertyFragment on Property {
    id
    propertyAddressSlugId
    propertyVersionAddressSlugId
    createdAt
    propertyInfo {
      id
      propertyName
      propertyFullName
      propertyCityState
      streetViewUrl
      satelliteViewUrl
    }
  }

  query accountPropertyByPropertyId($propertyId: ID!) {
    accountPropertyByPropertyId(propertyId: $propertyId) {
      id
      attributes
      claimantInfo
      preferences {
        allowMessageRequests
        moveOutMinYears
        moveOutMaxYears
      }
      property {
        ...MyAccounProperty_PropertyFragment
      }
      currentCollaborators {
        id
      }
      managedByUsers {
        id
      }
    }
  }
`;
