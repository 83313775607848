import { useQuery, useMutation } from '@apollo/client';
import { PropertyRelationship } from '@digsup/constants';
import { Account } from '@/fe-common/types/graphql-types';

import {
  INITIATE_CLAIM,
  SET_CLAIMANT_LABEL,
  RELEASE_CLAIM_ON_PROPERTY,
  INVITE_USERS_TO_ACCOUNT,
  ACCOUNT_BY_ID,
  SEND_COLLABORATION_INVITATION,
  SET_ACCOUNT_PROPERTY_PREFERENCES,
  UPDATE_ONBOARDING_PROGRESS,
  FINALIZE_ONBOARDING,
} from './gql';

export const useOnboarding = accountId => {
  const { data } = useQuery(ACCOUNT_BY_ID, {
    variables: { id: accountId },
    fetchPolicy: 'network-only',
    skip: !accountId,
  });

  const [sendCollaborationInvitationMutation] = useMutation(
    SEND_COLLABORATION_INVITATION,
  );
  const [setAccountPropertyPreferencesMutation] = useMutation(
    SET_ACCOUNT_PROPERTY_PREFERENCES,
  );
  const [initiateClaimMutation] = useMutation(INITIATE_CLAIM);
  const [setClaimantLabelMutation] = useMutation(SET_CLAIMANT_LABEL);
  const [releaseClaimOnPropertyMutation] = useMutation(
    RELEASE_CLAIM_ON_PROPERTY,
  );
  const [inviteUsersToAccountMutation] = useMutation(INVITE_USERS_TO_ACCOUNT);
  const [updateOnboardingProgress] = useMutation(UPDATE_ONBOARDING_PROGRESS);
  const [finalizeOnboarding] = useMutation(FINALIZE_ONBOARDING);

  const confirmRelationShipSelected = async ({ building, relationship }) => {
    const account = data?.accountById;
    const onboardingProgress = { ...account?.onboardingProgress };
    const onboardingDetails = { ...account?.onboardingDetails };

    onboardingDetails.buildingType = building;
    onboardingDetails.claimantLabel = relationship;

    // Incase the user came back to this step after already claiming a
    // property, the we need to release that property.
    let releaseClaimPromise = Promise.resolve();
    if (onboardingDetails?.accountPropertyId) {
      releaseClaimPromise = releaseClaimOnPropertyMutation({
        variables: {
          accountPropertyId: onboardingDetails?.accountPropertyId,
        },
      }).then(() => {
        onboardingDetails.accountPropertyId = '';
      });
    }

    return releaseClaimPromise.then(() =>
      updateOnboardingProgress({
        variables: {
          accountId: account?.id,
          onboardingProgress,
          onboardingDetails,
        },
      }),
    );
  };

  // Utility function to initiate the claim on a property.
  const propertySelected = async (
    streetViewUrl,
    satelliteViewUrl,
    googlePlaceId,
    propertyId,
    propertyName,
    claimantLabel,
    subUnitType,
  ) => {
    const account: Account = data?.accountById;
    const onboardingProgress = { ...account?.onboardingProgress };
    const onboardingDetails = { ...account?.onboardingDetails };

    // Mark the relevant steps as completed
    onboardingProgress.currentProperty = true;
    onboardingProgress.propertyRelationship = true;

    // Update the data in onboarding details
    onboardingDetails.googlePlaceId = googlePlaceId;
    onboardingDetails.subUnitType = subUnitType;
    onboardingDetails.propertyId = propertyId;
    onboardingDetails.propertyName = propertyName;
    onboardingDetails.streetViewUrl = streetViewUrl;
    onboardingDetails.satelliteViewUrl = satelliteViewUrl;
    onboardingDetails.claimantLabel = claimantLabel;

    // Incase the user came back to this step after already claiming a
    // property, the we need to release that property.
    let releaseClaimPromise = Promise.resolve();
    if (onboardingDetails?.accountPropertyId) {
      releaseClaimPromise = releaseClaimOnPropertyMutation({
        variables: {
          accountPropertyId: onboardingDetails?.accountPropertyId,
        },
      }).then(() => {
        onboardingDetails.accountPropertyId = '';
      });
    }

    return releaseClaimPromise.then(() =>
      initiateClaimMutation({
        variables: {
          propertyId,
          claimantLabel,
          subUnitType,
        },
      }).then(res => {
        onboardingDetails.accountPropertyId = res?.data?.initiateClaim?.id;
        updateOnboardingProgress({
          variables: {
            accountId: account?.id,
            onboardingProgress,
            onboardingDetails,
          },
        });
      }),
    );
  };

  // Utility function to set the preferences for the claimed property.
  const setAccountPropertyPreferences = async (
    moveOutMinYears,
    moveOutMaxYears,
    allowMessageRequests,
  ) => {
    const account = data?.accountById;
    const onboardingProgress = { ...account?.onboardingProgress };
    const onboardingDetails = { ...account?.onboardingDetails };
    const { accountPropertyId } = onboardingDetails;

    // Mark the relevant steps as completed
    onboardingProgress.movingPlan = true;
    // Update the data in onboarding details
    onboardingDetails.moveOutMinYears = moveOutMinYears;
    onboardingDetails.moveOutMaxYears = moveOutMaxYears;
    onboardingDetails.allowMessageRequests = allowMessageRequests;

    return setAccountPropertyPreferencesMutation({
      variables: {
        accountId: account?.id,
        accountPropertyId,
        moveOutMinYears,
        moveOutMaxYears,
        allowMessageRequests,
        onboardingProgress,
        onboardingDetails,
      },
    });
  };

  // Utility function to specify collaborator for the claimed property.
  const specifyCollaborator = async (emailAddress, collaborationLabel) => {
    const account = data?.accountById;
    const onboardingProgress = { ...account?.onboardingProgress };
    const onboardingDetails = { ...account?.onboardingDetails };

    // Mark the relevant steps as completed
    onboardingProgress.specifyCollaborator = true;
    // Update the data in onboarding details
    onboardingDetails.collaboratorEmail = emailAddress;
    onboardingDetails.collaboratorLabel = collaborationLabel;

    return updateOnboardingProgress({
      variables: {
        onboardingDetails,
        onboardingProgress,
        accountId: account?.id,
      },
    });
  };

  // Utility function to confirm collaborator for the claimed property.
  const confirmCollaborator = async () => {
    const account = data?.accountById;
    const onboardingProgress = { ...account?.onboardingProgress };
    const onboardingDetails = { ...account?.onboardingDetails };
    const { propertyId, collaboratorEmail, collaboratorLabel } =
      onboardingDetails;

    // Mark the relevant steps as completed
    onboardingProgress.confirmCollaborator = true;

    return sendCollaborationInvitationMutation({
      variables: {
        accountId: account?.id,
        propertyId,
        emailAddress: collaboratorEmail,
        collaborationLabel: collaboratorLabel,
        isAccountScope: false,
        onboardingProgress,
        onboardingDetails,
      },
    });
  };

  // Utility function to skip inviting collaborators to the claimed property.
  // Updates the onboardingProgress and onbaordingData on the account.
  const skipCollaborationInvitation = async () => {
    const account = data?.accountById;
    const onboardingProgress = { ...account?.onboardingProgress };
    const onboardingDetails = { ...account?.onboardingDetails };

    // Mark the relevant steps as completed
    onboardingProgress.specifyCollaborator = true;
    onboardingProgress.confirmCollaborator = true;

    return updateOnboardingProgress({
      variables: {
        accountId: account?.id,
        onboardingProgress,
        onboardingDetails,
      },
    });
  };

  // Utility function to specify users to invite to the account.
  const specifyAccountUsers = async emailAddresses => {
    const account = data?.accountById;
    const onboardingProgress = { ...account?.onboardingProgress };
    const onboardingDetails = { ...account?.onboardingDetails };

    // Mark the relevant steps as completed
    onboardingProgress.specifyAccountUsers = true;
    // Update the data in onboarding details
    onboardingDetails.accountUserEmails = emailAddresses;

    return updateOnboardingProgress({
      variables: {
        accountId: account?.id,
        onboardingProgress,
        onboardingDetails,
      },
    });
  };

  // Utility function to invite users to the account. Additionally
  // updates the onboardingProgress and onbaordingData on the account as well.
  const confirmAccountUsers = async () => {
    const account = data?.accountById;
    const onboardingProgress = { ...account?.onboardingProgress };
    const onboardingDetails = { ...account?.onboardingDetails };
    const { accountUserEmails } = onboardingDetails;

    // Mark the relevant steps as completed
    onboardingProgress.confirmAccountUsers = true;

    // During onboarding we don't have input for relationship
    // We will just hardcode that to co-owner.
    const accountInvitees = accountUserEmails.map(email => ({
      emailAddress: email,
      relationshipLabel: PropertyRelationship.CO_OWNER,
    }));

    return inviteUsersToAccountMutation({
      variables: {
        accountId: account?.id,
        accountInvitees,
        onboardingProgress,
        onboardingDetails,
      },
    });
  };

  // Utility function to skip inviting users to the account.
  // Updates the onboardingProgress and onbaordingData on the account.
  const skipAccountUsers = async (claimantLabel: string) => {
    const account = data?.accountById;
    const onboardingProgress = { ...account?.onboardingProgress };
    const onboardingDetails = { ...account?.onboardingDetails };
    const { accountPropertyId } = onboardingDetails;

    if (claimantLabel === PropertyRelationship.RENTER) {
      onboardingProgress.confirmCollaborator = true;
    } else if (claimantLabel === PropertyRelationship.CO_OWNER) {
      setClaimantLabelMutation({
        variables: {
          accountPropertyId,
          claimantLabel: PropertyRelationship.SOLE_OWNER,
        },
      }).then(() => {
        onboardingDetails.claimantLabel = PropertyRelationship.SOLE_OWNER;
      });
    }
    // Mark the relevant steps as completed
    onboardingProgress.specifyAccountUsers = true;
    onboardingProgress.confirmAccountUsers = true;

    return updateOnboardingProgress({
      variables: {
        accountId: account?.id,
        onboardingProgress,
        onboardingDetails,
      },
    });
  };

  // Utility function to be called after user finishes property ratings.
  // Updates the onboardingProgress and onbaordingData on the account.
  const setOnboardingCompleted = async () => {
    const account = data?.accountById;
    const onboardingProgress = { ...account?.onboardingProgress };
    const onboardingDetails = { ...account?.onboardingDetails };

    // Mark the relevant steps as completed
    onboardingProgress.rateProperties = true;

    return updateOnboardingProgress({
      variables: {
        accountId: account?.id,
        onboardingProgress,
        onboardingDetails,
      },
    }).then(() => {
      finalizeOnboarding();
    });
  };

  return {
    account: data?.accountById,
    confirmRelationShipSelected,
    propertySelected,
    setAccountPropertyPreferences,
    specifyCollaborator,
    confirmCollaborator,
    skipCollaborationInvitation,
    specifyAccountUsers,
    confirmAccountUsers,
    skipAccountUsers,
    setOnboardingCompleted,
  };
};
