import { gql } from '@apollo/client';

export const SEND_BETA_INVITATION = gql`
  mutation sendBetaInvitation($emailAddress: String!) {
    sendBetaInvitation(emailAddress: $emailAddress) {
      status
      account {
        id
        betaInvitesSent
        betaInvitesAllowed
      }
    }
  }
`;
