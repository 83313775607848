import { useState } from 'react';

export function useWizard<T>(steps = []) {
  const [wizardData, setWizardData] = useState<T>({} as T);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [lastStepIndex, setLastStepIndex] = useState(0);

  const updateWizardData = async (newWizardData: T) => {
    const existingWizardData = wizardData;
    return setWizardData({
      ...existingWizardData,
      ...newWizardData,
    });
  };

  const reset = () => {
    if (wizardData || activeStepIndex !== 0) {
      setWizardData(null);
      setActiveStepIndex(0);
    }
  };

  const goToNextStep = (offset = 1) => {
    setLastStepIndex(activeStepIndex);
    const stepIndex = activeStepIndex + offset;
    if (stepIndex <= steps?.length - 1) {
      setActiveStepIndex(stepIndex);
    }
  };

  const goToPrevStep = offset => {
    const stepIndex = offset ? activeStepIndex - offset : lastStepIndex;
    if (stepIndex >= 0) {
      setActiveStepIndex(stepIndex);
    }
  };

  const getActiveStep = () => steps?.[activeStepIndex];

  return {
    wizardData,
    updateWizardData,
    activeStepIndex,
    setActiveStepIndex,
    getActiveStep,
    goToNextStep,
    goToPrevStep,
    reset,
  };
}
