import { useQuery } from '@apollo/client';
import { PermissionResult } from '@/fe-common/types/graphql-types';

import { GET_AVAILABLE_LICENSES } from './gql';

export const useAccountLicenses = (permissionResult: PermissionResult) => {
  const permissionResultInput = {
    permission: permissionResult?.permission,
    issues: permissionResult?.issues?.map(issue => issue.type)
  };

  const { data, loading, error } = useQuery(
    GET_AVAILABLE_LICENSES,
    {
      skip: !permissionResult,
      fetchPolicy: 'network-only',
      variables: {
        permissionResult: permissionResultInput,
      },
    },
  );

  return {
    accountLicensesData: data?.getAvailableLicenses,
    accountLicensesLoading: loading,
    accountLicensesError: error,
  };
};
