import { useEffect } from 'react';
import { addListener } from '@reduxjs/toolkit';
import { setNotifications } from '@/fe-common/redux/reducers/notification';
import { reduxStore, RootState } from '@/fe-common/redux/redux-store'

/**
 * The purpose of this hook is to take on the responsibility of
 * unregistering the listener when it is unloaded.
 * @param {*} listenerFunction - Function to call when notification is received
 */
export const useAllNotificationsObserver = (listenerFunction, callAtStartup = true) => {
  let unsubscribeFunction;

  useEffect(() => {
    if (!unsubscribeFunction) {
      if (callAtStartup) {
        listenerFunction();
      }

      unsubscribeFunction = reduxStore.dispatch(addListener({
        actionCreator: setNotifications,
        effect: (action, { getOriginalState }) => {
          const {
            notifications,
            initialNotifications,
          } = action.payload;

          if (initialNotifications || notifications.length === 0) return;

          // Filter out existing notifications from the list. We have
          // to call the listener function only for new notifications.
          const originalState = getOriginalState() as RootState;
          const existingNotifications = originalState.notifications.allNotifications;
          const filteredNotifications = notifications.filter(notification => {
            return existingNotifications.findIndex(
              n => n?.id === notification.id,
            ) === -1;
          });

          if (filteredNotifications.length > 0) {
            listenerFunction();
          };
        }
      }));
    }

    // Return function to unsubscribe the listener when unloading
    return () => {
      unsubscribeFunction?.();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
