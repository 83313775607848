import { gql } from '@apollo/client';
import { ACCOUNT_FRAGMENTS } from '@/fe-graphql';

export const ACCOUNT_SUMMARY = gql`
  query accountById($id: ID!) {
    accountById(id: $id) {
      id
      ...OnboardingDetails
      ...OnboardingProgress
      searchProfiles {
        id
      }
      summary {
        id
        allowedBookmarkedPropertiesCount
        currentBookmarkedPropertiesCount
        recentBookmarkedProperties {
          id
        }

        allowedDreamHomesCount
        currentDreamHomesCount
        recentDreamHomes {
          id
        }

        currentRatedPropertiesCount
        recentRatedProperties {
          id
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENTS.onboardingDetails}
  ${ACCOUNT_FRAGMENTS.onboardingProgress}
`;
