import { gql } from '@apollo/client';

export const GET_AVAILABLE_LICENSES = gql`
  query getAvailableLicenses($permissionResult: PermissionResultInput) {
    getAvailableLicenses(permissionResult: $permissionResult) {
      id
      displayName
      licenseImage
      trialDays
      trialRequiresCC
      monthlyCost
      licenseCost
      upgradePossible
      downgradePossible
      features {
        description
        availability
      }
    }
  }
`;
