import { gql } from '@apollo/client';

export const REMOVE_COLLABORATOR = gql`
  mutation removeCollaborator($accountPropertyId: ID!, $collaboratorId: ID!) {
    removeCollaborator(
      accountPropertyId: $accountPropertyId
      collaboratorId: $collaboratorId
    ) {
      id
      accountPropertyId
      accountProperty {
        id
        accountId
        propertyId
      }
    }
  }
`;
