import { gql } from '@apollo/client';

export const INITIATE_CLAIM = gql`
  mutation initiateClaim(
    $propertyId: ID!
    $claimantLabel: String!
    $subUnitType: String
  ) {
    initiateClaim(
      propertyId: $propertyId
      claimantLabel: $claimantLabel
      subUnitType: $subUnitType
    ) {
      id
    }
  }
`;
