import { gql } from '@apollo/client';
import {
  ACCOUNT_FRAGMENTS,
  PROPERTY_FRAGMENTS,
} from '@/fe-graphql';

export const MY_ACCOUNT = gql`
  fragment UserFragment on User {
    id
    firstName
    lastName
    email
    avatarUrl
    relationshipLabel
    createdAt
    updatedAt
  }

  query accountById($id: ID!) {
    accountById(id: $id) {
      id
      name
      ...OnboardingProgress
      betaInvitesSent
      betaInvitesAllowed
      summary {
        id
        allowedDreamHomesCount
      }
      currentLicense {
        id
        startDate
        endDate
        licenseTemplate {
          id
          name
          displayName
        }
      }
      allLicenses {
        id
        startDate
        endDate
        isCurrent
        paymentAmount
        licenseState
        createdAt
        licenseTemplate {
          id
          displayName
        }
      }
      paymentMethod {
        id
        nameOnCard
        cardBrand
        lastFourDigits
        expiryMonth
        expiryYear
        country
        zipCode
        billingAddress
        postalCodeCheck
      }
      accountPayments {
				id
				paymentType
				paymentAmount
				cardBrand
				lastFourDigits
				paymentDate
      }
      users {
        ...UserFragment
      }
      searchProfiles {
        id
      }
      accountInvitations {
        id
        invitedUserEmail
        relationshipLabel
        initiatedByAccountId
      }
      bookmarkedProperties {
        id
        count
        rows {
          id
          property {
            ...PropertySummary
          }
        }
      }
      claimedProperties {
        id
        count
        rows {
          id
          accountId
          propertyId
          attributes
          isAccountClaimant
          isAccountVerifiedClaimant
          isAccountOwner
          isAccountRenter
          property {
            ...PropertySummary
          }
          currentCollaborators {
            id
            collaboratingUser {
              ...UserFragment
            }
            collaborationLabel
          }
          collaborationInvitations {
            id
            invitedUserEmail
            collaborationLabel
            accountPropertyId
            initiatedByAccountId
          }
        }
      }
      collaboratingOnProperties {
        id
        count
        rows {
          id
          accountId
          propertyId
          property {
            ...PropertySummary
          }
          currentCollaborators {
            id
            collaborationLabel
            collaboratingAccountId
            collaboratingUser {
              ...UserFragment
            }
          }
          managedByUsers {
            ...UserFragment
          }
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENTS.onboardingProgress}
  ${PROPERTY_FRAGMENTS.propertySummary}
`;
