import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NotificationTypeDisplay } from '@digsup/constants';
import { Notification } from '../../types/graphql-types';

interface NotificationState {
  notificationsSuspended: boolean;
  initialNotificationsLoaded: boolean;
  allNotifications: Array<Notification>;
  notificationIdToShowFromContextPanel: string;
  activeNotificationId: string;
};

type SetNotificationsPayload = {
  initialNotifications: boolean;
  notifications: Array<Notification>;
};

type ClearNotificationPayload = {
  notificationId: string;
};

type SetNotificationIdToShowFromContextPanelPayload = {
  notificationId: string;
};

const initialState: NotificationState = {
  notificationsSuspended: false,
  initialNotificationsLoaded: false,
  allNotifications: [],
  notificationIdToShowFromContextPanel: null,
  activeNotificationId: null,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    suspendNotifications: (state) => {
      state.notificationsSuspended = true;
    },
    resumeNotifications: (state) => {
      const {
        allNotifications,
        notificationIdToShowFromContextPanel
      } = state;

      // When notifications are resumed we need to check if we have any
      // notification to display.
      if (notificationIdToShowFromContextPanel) {
        // If the user had clicked on a notification to show from the context panel
        // then set that as the active notification
        state.activeNotificationId = notificationIdToShowFromContextPanel;
        state.notificationIdToShowFromContextPanel = null;
      } else {
        // If we have any notification in our list that has not yet been shown then set
        // that as the active notification. 
        const notification = allNotifications?.find(n => !n.cleared && !n.shown);
        if (notification) {
          state.activeNotificationId = notification.id;
        }
      }

      state.notificationsSuspended = false;
    },

    markNotificationAsCleared: (state, action: PayloadAction<ClearNotificationPayload>) => {
      const { allNotifications } = state;
      const notificationId = action.payload.notificationId;
      const notificationIndex = allNotifications?.findIndex(
        n => n?.id === notificationId,
      );

      const notification = allNotifications[notificationIndex];
      const updatedNotification = {
        ...notification,
        shown: true,
        cleared: true,
      };

      allNotifications?.splice(notificationIndex, 1, updatedNotification);
    },

    markNotificationAsShown: (state, action: PayloadAction<ClearNotificationPayload>) => {
      const { allNotifications } = state;
      const notificationId = action.payload.notificationId;
      const notificationIndex = allNotifications?.findIndex(
        n => n?.id === notificationId,
      );

      const notification = allNotifications[notificationIndex];
      const updatedNotification = {
        ...notification,
        shown: true,
      };

      allNotifications?.splice(notificationIndex, 1, updatedNotification);
    },

    setNotificationIdToShowFromContextPanel: (state, action: PayloadAction<SetNotificationIdToShowFromContextPanelPayload>) => {
      const { activeNotificationId, notificationsSuspended } = state;
      state.notificationIdToShowFromContextPanel = action.payload.notificationId;
      if (!activeNotificationId && notificationsSuspended === false) {
        state.activeNotificationId = action.payload.notificationId;
      }
    },

    setNotifications: (state, action: PayloadAction<SetNotificationsPayload>) => {
      const newNotifications = action.payload.notifications;
      const {
        allNotifications,
        activeNotificationId,
        notificationsSuspended,
        notificationIdToShowFromContextPanel,
      } = state;

      state.initialNotificationsLoaded = true;
      newNotifications?.forEach(notification => {
        // Check if this is already in the list of notifications
        const notificationIndex = allNotifications?.findIndex(
          n => n?.id === notification?.id,
        );

        const notificationObj = { ...notification };
        if (notificationIndex === -1) {
          // Only put this notification into this list if it has
          // a displayAs value specified.
          if (notification?.metadata?.displayAs) {
            allNotifications?.push(notificationObj);
            const notificationDisplay = NotificationTypeDisplay[notificationObj.type];
            console.log(`Received new notification ${notificationObj.type} (${notificationObj.id}) - ${notificationDisplay}`)
          }
        } else {
          // This notification is already in the list of existing
          // notifications. Replace it if it has changed.
          const existingNotification = allNotifications[notificationIndex];

          if (
            existingNotification.shown !== notification.shown ||
            existingNotification.cleared !== notification.cleared
          ) {
            allNotifications?.splice(notificationIndex, 1, notificationObj);

            const notificationDisplay = NotificationTypeDisplay[existingNotification.type];
            console.log(`Cleared existing notification ${existingNotification.type} (${existingNotification.id}) - ${notificationDisplay}`)
          }
        }
      });

      // Whenever new notifications are received and we are not currently showing
      // an active notification, see if there is now one that can be shown
      if (!activeNotificationId && notificationsSuspended === false) {
        if (notificationIdToShowFromContextPanel) {
          // If the user had clicked on a notification to show from the context panel
          // then set that as the active notification
          state.activeNotificationId = notificationIdToShowFromContextPanel;
          state.notificationIdToShowFromContextPanel = null;
        } else {
          // If we have any notification in our list that has not yet been shown then set
          // that as the active notification. 
          const notification = allNotifications?.find(n => !n.cleared && !n.shown);
          if (notification) {
            state.activeNotificationId = notification.id;
          }
        }
      }
    },

    clearActiveNotificationId: (state) => {
      const {
        allNotifications,
        notificationsSuspended,
        notificationIdToShowFromContextPanel
      } = state;

      if (notificationsSuspended === false) {
        if (notificationIdToShowFromContextPanel) {
          // If the user had clicked on a notification to show from the context panel
          // then set that as the active notification
          state.activeNotificationId = notificationIdToShowFromContextPanel;
          state.notificationIdToShowFromContextPanel = null;
        } else {
          // If we have any notification in our list that has not yet been shown then set
          // that as the active notification. 
          const notification = allNotifications?.find(n => !n.cleared && !n.shown);
          if (notification) {
            state.activeNotificationId = notification.id;
          } else {
            state.activeNotificationId = null;
          }
        }
      } else {
        // Set the activeNotificationId to be null
        state.activeNotificationId = null;
      }
    },

    resetNotifications: (state) => {
      state = { ...initialState };
    },
  },
});

export const {
  suspendNotifications,
  resumeNotifications,
  markNotificationAsCleared,
  markNotificationAsShown,
  setNotificationIdToShowFromContextPanel,
  setNotifications,
  clearActiveNotificationId,
  resetNotifications,
} = notificationSlice.actions;
export default notificationSlice.reducer;