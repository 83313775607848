import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import applicationReducer from './reducers/application';
import notificationReducer from './reducers/notification';

// Create the listener middleware instance
const listenerMiddleware = createListenerMiddleware();

export const reduxStore = configureStore({
  reducer: {
    application: applicationReducer,
    notifications: notificationReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().prepend(listenerMiddleware.middleware),
});

export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;