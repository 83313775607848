import { gql } from '@apollo/client';
import { ACCOUNT_FRAGMENTS } from '@/fe-graphql';

export const FINALIZE_ONBOARDING = gql`
  mutation finalizeOnboarding {
    finalizeOnboarding {
      id
      ...OnboardingDetails
      ...OnboardingProgress
      summary {
        id
        allowedBookmarkedPropertiesCount
        currentBookmarkedPropertiesCount
        allowedDreamHomesCount
        currentDreamHomesCount
        currentRatedPropertiesCount
      }
    }
  }
  ${ACCOUNT_FRAGMENTS.onboardingDetails}
  ${ACCOUNT_FRAGMENTS.onboardingProgress}
`;
