import { gql } from '@apollo/client';

export const CHECK_INVITATION_TOKEN_VALID = gql`
  mutation checkInvitationTokenValid($invitationToken: String!) {
    checkInvitationTokenValid(invitationToken: $invitationToken) {
      isValid
      tokenEmail
    }
  }
`;
