import { gql } from '@apollo/client';

export const SEND_COLLABORATION_INVITATION = gql`
  mutation sendCollaborationInvitation(
    $emailAddress: String!
    $propertyId: ID!
    $collaborationLabel: String!
    $isAccountScope: Boolean!
  ) {
    sendCollaborationInvitation(
      emailAddress: $emailAddress
      propertyId: $propertyId
      collaborationLabel: $collaborationLabel
      isAccountScope: $isAccountScope
    ) {
      id
      invitedUserEmail
      collaborationLabel
      accountPropertyId
      initiatedByAccountId
    }
  }
`;
