import { gql } from '@apollo/client';

export const ACCOUNT_PAYMENT_DATA = gql`
  query accountById($id: ID!) {
    accountById(id: $id) {
      id
      trialAvailed
      hasActivePaymentMethod
      paymentMethod {
        id
        nameOnCard
        cardBrand
        lastFourDigits
        expiryMonth
        expiryYear
        billingAddress
      }
      claimedProperties {
        id
        rows {
          id
          property {
            id
            propertyVersionAddressSlugId
            propertyInfo {
              id
              propertyName
              propertyFullName
              propertyAddress {
                city
                state
                country
                postalCode
              }
            }
          }
        }
      }
    }
  }
`;
