import { useMutation } from '@apollo/client';
import { PermissionResult } from '@/fe-common/types/graphql-types';
import { PermissionResultExtended } from '@/fe-common/types/graphql-type-extensions';
import { useAppDispatch } from '@/fe-common/redux';
import { setPermissionResult } from '@/fe-common/redux/reducers/application';

import {
  CHECK_ACCOUNT_PERMISSION,
  CHECK_PROPERTY_PERMISSION,
} from './gql';

type UsePermissionsResult = {
  handlePermissionError: (permissionResult: PermissionResult) => void;
  checkAccountPermission: (permissionName: string, accountId?: string) => Promise<PermissionResultExtended>;
  checkPropertyPermission: (permissionName: string, propertyAddressSlugId: string, accountId?: string) => Promise<PermissionResultExtended>;
};

export const usePermissions = () => {
  const dispatch = useAppDispatch();
  const [checkAccountPermissionMutation] = useMutation(CHECK_ACCOUNT_PERMISSION);
  const [checkPropertyPermissionMutation] = useMutation(CHECK_PROPERTY_PERMISSION);

  const setPermissionResultForSystemModals = (permissionResult: PermissionResult) => {
    dispatch(setPermissionResult({ permissionResult }));
  }

  const checkAccountPermission = (permissionName: string, accountId?: string) => {
    return checkAccountPermissionMutation({
      variables: {
        accountId,
        permissionName,
      },
    }).then(({ data }) => {
      const permission = data?.checkAccountPermission;
      // Before returning, add a helper method to the permission
      // object to check for permission issues and show error modals
      const permissionObj = {
        ...permission,
        hasIssue: issueType => {
          if (!permissionObj.issues) return false;
          const index = permissionObj.issues.findIndex(
            issue => issue.type === issueType,
          );
          return index !== -1;
        },
      };

      return permissionObj;
    })
  };

  const checkPropertyPermission = (permissionName: string, propertyAddressSlugId: string, accountId?: string) => {
    if (!permissionName || !propertyAddressSlugId) return Promise.resolve(null);

    return checkPropertyPermissionMutation({
      variables: {
        accountId,
        propertyAddressSlugId,
        permissionName,
      },
    }).then(({ data }) => {
      const permission = data?.checkPropertyPermission;
      // Before returning, add a helper method to the permission
      // object to check for permission issues and show error modals
      const permissionObj = {
        ...permission,
        hasIssue: issueType => {
          if (!permissionObj.issues) return false;
          const index = permissionObj.issues.findIndex(
            issue => issue.type === issueType,
          );
          return index !== -1;
        },
      };

      return permissionObj;
    })
  };

  const result: UsePermissionsResult = {
    handlePermissionError: setPermissionResultForSystemModals,
    checkAccountPermission,
    checkPropertyPermission,
  };
  return result;
};
