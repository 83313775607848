import { gql } from '@apollo/client';
import { PROPERTY_FRAGMENTS } from '@/fe-graphql';

export const PROPERTY_SUMMARY = gql`
  query property(
    $googlePlaceId: String
    $propertyAddressSlugId: String
    $subUnitType: String
    $subUnitIdentifier: String
  ) {
    property(
      googlePlaceId: $googlePlaceId
      propertyAddressSlugId: $propertyAddressSlugId
      subUnitType: $subUnitType
      subUnitIdentifier: $subUnitIdentifier
    ) {
      ...PropertySummary
    }
  }
  ${PROPERTY_FRAGMENTS.propertySummary}
`;
