import { useMutation } from '@apollo/client';
import { uploadToS3 } from '@/fe-common/upload-to-s3';
import { GET_PRESIGN_PUT_OBJECT_URL } from '@/fe-graphql/mutations';

export const useS3 = () => {
  const [getPresignPutObjectUrl] = useMutation(GET_PRESIGN_PUT_OBJECT_URL);

  const uploadFile = async (blobUrl: string): Promise<any> =>
    // eslint-disable-next-line no-async-promise-executor
    new Promise(async resolve => {
      const image = await fetch(blobUrl).then(r => r?.blob());

      const fileName = blobUrl?.split('/')?.pop();

      const file = new File([image], fileName, {
        type: image?.type,
      });

      const { data } = await getPresignPutObjectUrl({
        variables: {
          fileName,
          fileType: file?.type,
        },
      });
      const presignUrl = data?.getPresignPutObjectUrl;
      const [imageUrl] = presignUrl?.split('?');
      uploadToS3(file, presignUrl, null, () => {
        resolve({
          imageUrl,
        });
      });
    });

  return {
    uploadFile,
    getPresignPutObjectUrl,
  };
};
