import { gql } from '@apollo/client';

export const CREATE_PROPERTY_RATING = gql`
  mutation createPropertyRating(
    $propertyId: ID!
    $ratings: RatingsInput!
    $searchProfileId: ID
  ) {
    createPropertyRating(
      propertyId: $propertyId
      ratings: $ratings
      searchProfileId: $searchProfileId
    ) {
      id
      searchProfileId
      userId
      propertyId
      ratings {
        liked
        dreamHome
        goodPrice
        goodLocation
        goodHouse
        badPrice
        badLocation
        badHouse
      }
    }
  }
`;
