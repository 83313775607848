import { useEffect } from 'react';
import { useAppDispatch } from '@/fe-common/redux';
import {
  suspendNotifications,
  resumeNotifications,
} from '@/fe-common/redux/reducers/notification';

/**
 * The purpose of this hook is to take on the responsibility of
 * suspending the notifications when loaded, and resuming them
 * when unloaded.
 */
export const useNotificationsSuspender = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Suspend the notifications.
    dispatch(suspendNotifications());

    // Return function to resume the notifications when unloading
    return () => {
      dispatch(resumeNotifications());
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
};
