import { gql } from '@apollo/client';

export const SET_ACCOUNT_PROPERTY_PREFERENCES = gql`
  mutation setAccountPropertyPreferences(
    $accountPropertyId: ID!
    $moveOutMinYears: Int
    $moveOutMaxYears: Int
    $allowMessageRequests: Boolean
  ) {
    setAccountPropertyPreferences(
      accountPropertyId: $accountPropertyId
      preferences: {
        moveOutMinYears: $moveOutMinYears
        moveOutMaxYears: $moveOutMaxYears
        allowMessageRequests: $allowMessageRequests
      }
    ) {
      id
      preferences {
        allowMessageRequests
        moveOutMinYears
        moveOutMaxYears
      }
    }
  }
`;
