import { gql } from '@apollo/client';

export const CREATE_MESSAGE = gql`
  mutation createMessage($messageThreadId: ID!, $messageBody: String!) {
    createMessage(
      messageThreadId: $messageThreadId
      messageBody: $messageBody
    ) {
      id
      messageThreadId
      messageType
      messageBody
      durationAgo
      createdAt
    }
  }
`;
