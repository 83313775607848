import { gql } from '@apollo/client';

export const DISMISS_NOTIFICATION = gql`
  mutation dismissNotification($notificationId: String!) {
    dismissNotification(notificationId: $notificationId) {
      id
      shown
    }
  }
`;
