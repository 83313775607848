import { gql } from '@apollo/client';

export const ADD_PROPERTY_IMAGES = gql`
  mutation addPropertyImages(
    $accountPropertyId: ID!
    $images: [AccountPropertyImageInput]
  ) {
    addPropertyImages(accountPropertyId: $accountPropertyId, images: $images) {
      id
      additionalImages {
        id
        imageUrl
        label
        category
        description
        userId
      }
    }
  }
`;
