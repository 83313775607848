import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PermissionResult } from '../../types/graphql-types';

export interface ApplicationState {
  permissionResult: PermissionResult;
};

export type SetPermissionResultPayload = {
  permissionResult: PermissionResult;
};

const initialState: ApplicationState = {
  permissionResult: null,
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setPermissionResult: (state, action: PayloadAction<SetPermissionResultPayload>) => {
      state.permissionResult = action.payload.permissionResult;
    },
    clearPermissionResult: (state) => {
      state.permissionResult = null;
    },
  },
});

export const {
  setPermissionResult,
  clearPermissionResult,
} = applicationSlice.actions;
export default applicationSlice.reducer;