import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateUser(
    $id: ID!
    $firstName: String
    $lastName: String
    $email: String
    $profileBlurb: String
    $avatarUrl: String
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      profileBlurb: $profileBlurb
      avatarUrl: $avatarUrl
    ) {
      id
      firstName
      lastName
      email
      profileBlurb
      avatarUrl
    }
  }
`;
