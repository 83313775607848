import { gql } from '@apollo/client';

export const SET_PROPERTY_VALUES = gql`
  mutation setPropertyValues(
    $accountPropertyId: ID!
    $propertyFieldValues: [AccountPropertyFieldValueInput]!
  ) {
    setPropertyValues(
      accountPropertyId: $accountPropertyId
      propertyFieldValues: $propertyFieldValues
    ) {
      id
      propertyDetails {
        id
        allStructuresLivingM2
        improvementYear
        bathsTotal
        bedrooms
        parkingType
        structureLevels
        taxYearAmount
        assdTotal
        coolingType
        flooringType
        heatingFuel
        heatingType
        lotSizeM2
        poolType
        poolFeatures
        structureType1
        structureOuterWalls
        structureYear
        latitude
        longitude
      }
    }
  }
`;
