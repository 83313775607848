import { gql } from '@apollo/client';
import { ACCOUNT_FRAGMENTS } from '@/fe-graphql';

export const SIGNUP_USER = gql`
  mutation signUpUser(
    $username: String!
    $password: String!
    $firstName: String!
    $timezone: String!
    $lastName: String!
    $dateOfBirth: DateTime!
    $facebookProfileId: String
    $twitterProfileId: String
    $googleProfileId: String
    $avatarUrl: String
    $invitationCode: String
    $invitationToken: String
  ) {
    signUpUser(
      email: $username
      password: $password
      timezone: $timezone
      firstName: $firstName
      lastName: $lastName
      dateOfBirth: $dateOfBirth
      facebookProfileId: $facebookProfileId
      twitterProfileId: $twitterProfileId
      googleProfileId: $googleProfileId
      avatarUrl: $avatarUrl
      invitationCode: $invitationCode
      invitationToken: $invitationToken
    ) {
      token
      timezoneStatus
      user {
        id
        firstName
        lastName
        email
        emailVerified
        profileBlurb
        accountId
        settings {
          emailPreferences {
            missedNotifications
            featureAndServiceUpdates
            promoAndSpecialOffers
            newPropertyMatches
          }
          localizationSettings {
            timezone
            twelveHourFormat
            temperatureInCelsius
          }
        }
        account {
          id
          name
          ...OnboardingProgress
          searchProfiles {
            id
          }
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENTS.onboardingProgress}
`;
