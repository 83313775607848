import { gql } from '@apollo/client';

export const PROPERTY_FRAGMENTS = {
  propertySummary: gql`
    fragment PropertySummary on Property {
      id
      propertyAddressSlugId
      propertyVersionAddressSlugId
      addressValidated
      createdAt
      propertyInfo {
        id
        streetViewUrl
        satelliteViewUrl
        hasFeatureData
        isMultiUnitProperty
        isSubUnitProperty
        parentPropertyAddressSlugId
        propertyName
        propertyFullName
        propertyCityState
        propertyAddress {
          subPremiseType
          subPremiseIdentifier
          streetNumber
          street
          city
          state
          country
          postalCode
        }
      }
      propertyVersionData {
        id
        propertyDetails {
          id
          allStructuresGrossM2
          allStructuresLivingM2
          archStyle
          baths1qtr
          bathsHalf
          baths3qtr
          bathsFull
          bathsTotal
          bedrooms
          coolingType
          flooringType
          heatingFuel
          heatingType
          improvementYear
          structureYear
          lotSizeM2
          parkingType
          poolFeatures
          poolType
          structureLevels
          structureOuterWalls
          structureType1
        }
      }
    }
  `,

  propertyData: gql`
    fragment PropertyData on Property {
      id
      propertyAddressSlugId
      propertyVersionAddressSlugId
      addressValidated
      propertyInfo {
        id
        streetViewUrl
        satelliteViewUrl
        hasFeatureData
        isMultiUnitProperty
        isSubUnitProperty
        parentPropertyAddressSlugId
        isCurrentClaimVerified
        propertyName
        propertyFullName
        propertyCityState
        propertyLocation {
          latitude
          longitude
        }
        propertyAddress {
          subPremiseType
          subPremiseIdentifier
          streetNumber
          street
          city
          state
          country
          postalCode
        }
      }
      propertyKpiData {
        currentPrice {
          lowValue
          highValue
        }
        moveOutInYears
        moveOutYearPrice {
          lowValue
          highValue
        }
        availability
        availabilityPrice {
          lowValue
          highValue
        }
        saleSpeed
        investmentRisk
      }
      propertyFeedbackData {
        showFeedback
        hasPriceFeedback
        priceFeedback
        hasLocationFeedback
        locationFeedback
        hasHouseFeedback
        houseFeedback
      }
      propertyCommunityData {
        populationDensity
        populationTrend
        medianAge
        medianAgeCategory
        medianHouseholdIncome
        ownPercentage
        rentPercentage
        typicalCommute
        typicalCommuteCategory
        climateRisk {
          hurricanes
          tornado
          earthquakes
          wind
          pollution
          ice
        }
        crimeData {
          mortality
          murder
          forcibleRape
          forcibleRobbery
          aggravatedAssault
          burglary
          larceny
          motorVehicleTheft
          crime
        }
        internetProviders {
          name
          downloadSpeed
          uploadSpeed
          technology
        }
      }
      propertyClimateData {
        summerTemperature {
          dayFahrenheit
          nightFahrenheit
          dayHumidity
          dayHumidityDescription
          nightHumidity
          nightHumidityDescription
          precipitation
        }
        winterTemperature {
          dayFahrenheit
          nightFahrenheit
          dayHumidity
          dayHumidityDescription
          nightHumidity
          nightHumidityDescription
          precipitation
        }
        gardeningZone
      }
    }
  `,

  propertyVersionData: gql`
    fragment PropertyVersionData on Property {
      id
      propertyAddressSlugId
      propertyVersionAddressSlugId
      addressValidated
      propertyVersionData {
        id
        accountPropertyId
        propertyDescription
        propertyDetails {
          id
          allStructuresLivingM2
          improvementYear
          archStyle
          baths1qtr
          bathsHalf
          baths3qtr
          bathsFull
          bathsTotal
          bedrooms
          parkingType
          structureLevels
          taxYearAmount
          assdTotal
          coolingType
          flooringType
          heatingFuel
          heatingType
          lotSizeM2
          poolType
          poolFeatures
          structureType1
          structureOuterWalls
          structureYear
          latitude
          longitude
        }
        additionalImages {
          id
          imageUrl
          label
          category
          description
          userId
          accountId
        }
      }
    }
  `,

  propertyUserData: gql`
    fragment PropertyUserData on Property {
      id
      propertyAddressSlugId
      propertyVersionAddressSlugId
      addressValidated
      propertyUserData {
        id
        relationshipFlags {
          id
          isPropertyAdmin
          isClaimant
          isVerifiedClaimant
          isUnverifiedClaimant
          isQueuedClaimant
          isOwner
          isRenter
          isCollaborator
          isTracked
          isBookmarked
        }
        propertyRating {
          id
          ratings {
            liked
            dreamHome
            goodPrice
            goodLocation
            goodHouse
            badPrice
            badLocation
            badHouse
          }
        }
        unreadMessagesCount
        messageThreads {
          id
          status
          participantCount
          unreadMessagesCount
          lastMessage {
            id
            messageBody
            durationAgo
            identity {
              user {
                id
                firstName
                lastName
                avatarUrl
                profileBlurb
              }
              labels
            }
          }
        }
      }
    }
  `,

  dreamHomeCard: gql`
    fragment DreamHomeCard on Property {
      id
      propertyAddressSlugId
      propertyVersionAddressSlugId
      createdAt
      updatedAt
      propertyInfo {
        id
        propertyName
        propertyFullName
        propertyCityState
        streetViewUrl
        satelliteViewUrl
      }
      propertyVersionData {
        id
        propertyDetails {
          id
          avmLow
          avmHigh
        }
      }
      propertyKpiData {
        currentPrice {
          lowValue
          highValue
        }
        availability
        availabilityPrice {
          lowValue
          highValue
        }
      }
    }
  `,

  propertyCard: gql`
    fragment PropertyCard on Property {
      id
      propertyAddressSlugId
      propertyVersionAddressSlugId
      createdAt
      updatedAt
      propertyInfo {
        id
        propertyName
        propertyFullName
        propertyCityState
        streetViewUrl
        satelliteViewUrl
        propertyAddress {
          subPremiseType
          subPremiseIdentifier
          streetNumber
          street
          city
          state
          country
          postalCode
        }
      }
      propertyUserData {
        id
        propertyRating {
          id
          ratings {
            liked
            dreamHome
            goodPrice
            goodLocation
            goodHouse
            badPrice
            badHouse
            badLocation
          }
        }
      }
    }
  `,
};
