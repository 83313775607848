import { gql } from '@apollo/client';

export const HAS_ACCOUNT_PERMISSION = gql`
  query hasAccountPermission($accountId: String, $permissionName: String!) {
    hasAccountPermission(accountId: $accountId, permissionName: $permissionName) {
      permission
      hasPermission
      issues {
        type
        description
        action
      }
    }
  }
`;
