import { useMutation } from '@apollo/client';
import {
  CREATE_SETUP_INTENT,
  CREATE_PAYMENT_INTENT,
  HANDLE_SETUP_SUCCEEDED,
  HANDLE_PAYMENT_SUCCEEDED,
  HANDLE_CREDIT_CARD_FAILED,
} from '@/fe-graphql/mutations';

export const useAccountPayment = () => {
  const [createSetupIntentMutation] = useMutation(CREATE_SETUP_INTENT);
  const [createPaymentIntentMutation] = useMutation(CREATE_PAYMENT_INTENT);
  const [handleSetupSucceededMutation] = useMutation(HANDLE_SETUP_SUCCEEDED);
  const [handlePaymentSucceededMutation] = useMutation(HANDLE_PAYMENT_SUCCEEDED);
  const [handleCreditCardFailedMutation] = useMutation(HANDLE_CREDIT_CARD_FAILED);

  const createSetupIntent = async () =>
    createSetupIntentMutation().then(res =>
      res?.data?.createSetupIntent?.clientSecret
    );

  const createPaymentIntent = async (licenseTemplateId: string, paymentMethodId?: string, referralCode?: string) =>
    createPaymentIntentMutation({
      variables: {
        licenseTemplateId,
        paymentMethodId,
        referralCode
      },
    }).then(res =>
      res?.data?.createPaymentIntent?.clientSecret
    );

  const handleSetupSucceeded = async (setupIntentId?: string, licenseTemplateId?: string) =>
    handleSetupSucceededMutation({
      variables: {
        setupIntentId,
        licenseTemplateId,
      },
    });

  const handlePaymentSucceeded = async (paymentIntentId: string, licenseTemplateId: string) =>
    handlePaymentSucceededMutation({
      variables: {
        paymentIntentId,
        licenseTemplateId,
      },
    });

  const handleCreditCardFailed = async () =>
    handleCreditCardFailedMutation().then(({ data }) => {
      return data.handleCreditCardFailed;
    });

  return {
    createSetupIntent,
    createPaymentIntent,
    handleSetupSucceeded,
    handlePaymentSucceeded,
    handleCreditCardFailed,
  };
};
