import { gql } from '@apollo/client';

export const DELETE_PROPERTY_IMAGE = gql`
  mutation deletePropertyImage($accountPropertyId: ID!, $imageId: String) {
    deletePropertyImage(
      accountPropertyId: $accountPropertyId
      imageId: $imageId
    ) {
      id
      additionalImages {
        id
        imageUrl
        label
        category
        description
        userId
      }
    }
  }
`;
