import { gql } from '@apollo/client';
import { ACCOUNT_FRAGMENTS } from '@/fe-graphql';

export const INVITE_USERS_TO_ACCOUNT = gql`
  mutation inviteUsersToAccount(
    $accountId: ID!
    $accountInvitees: [AccountInvitee]!
    $onboardingProgress: JSONObject!
    $onboardingDetails: JSONObject!
  ) {
    sendJoinAccountInvitation(
      accountId: $accountId
      accountInvitees: $accountInvitees
    ) {
      id
    }
    updateOnboardingProgress(
      accountId: $accountId
      onboardingProgress: $onboardingProgress
      onboardingDetails: $onboardingDetails
    ) {
      id
      ...OnboardingDetails
      ...OnboardingProgress
    }
  }
  ${ACCOUNT_FRAGMENTS.onboardingDetails}
  ${ACCOUNT_FRAGMENTS.onboardingProgress}
`;
