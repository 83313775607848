import { gql } from '@apollo/client';
import { ACCOUNT_FRAGMENTS } from '@/fe-graphql';

export const SEND_COLLABORATION_INVITATION = gql`
  mutation sendCollaborationInvitation(
    $accountId: ID!
    $emailAddress: String!
    $propertyId: ID!
    $collaborationLabel: String!
    $isAccountScope: Boolean!
    $onboardingProgress: JSONObject!
    $onboardingDetails: JSONObject!
  ) {
    sendCollaborationInvitation(
      emailAddress: $emailAddress
      propertyId: $propertyId
      collaborationLabel: $collaborationLabel
      isAccountScope: $isAccountScope
    ) {
      id
    }
    updateOnboardingProgress(
      accountId: $accountId
      onboardingProgress: $onboardingProgress
      onboardingDetails: $onboardingDetails
    ) {
      id
      ...OnboardingDetails
      ...OnboardingProgress
    }
  }
  ${ACCOUNT_FRAGMENTS.onboardingDetails}
  ${ACCOUNT_FRAGMENTS.onboardingProgress}
`;
