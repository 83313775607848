import { gql } from '@apollo/client';

export const FLAG_MESSAGE = gql`
  mutation flagMessage($messageId: ID!) {
    flagMessage(
      messageId: $messageId
    ) {
      id
      flaggedByUserId
      updatedAt
    }
  }
`;
