import { gql } from '@apollo/client';

export const FINALIZE_CLAIM = gql`
  mutation finalizeClaim($accountPropertyId: ID!) {
    finalizeClaim(accountPropertyId: $accountPropertyId) {
      id
      accountId
      propertyId
      attributes
      claimantInfo
      property {
        id
        propertyAddressSlugId
        propertyVersionAddressSlugId
        createdAt
        propertyInfo {
          id
          propertyName
          propertyFullName
          propertyCityState
          streetViewUrl
          satelliteViewUrl
        }
      }
      currentCollaborators {
        id
        collaboratingUser {
          id
          firstName
          lastName
          email
          avatarUrl
        }
        collaborationLabel
      }
      collaborationInvitations {
        id
        invitedUserEmail
        collaborationLabel
      }
    }
  }
`;
