import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ErrorNames } from '@digsup/constants';
import { Property } from '@/fe-common/types/graphql-types';
import { SEND_COLLABORATION_INVITATION } from '@/fe-graphql/mutations';

import { PROPERTY_SUMMARY } from './gql';

type UsePropertySummaryResult = {
  refetch: (args: any) => Promise<any>;
  property: Property;
  propertyLoading: boolean;
  propertyError: any;
  propertyErrorMessage: string;
  getProperty: (
    googlePlaceId: string,
    subUnitType?: string,
    subUnitIdentifier?: string,
  ) => void;
  sendCollaborationInvitation: (args: any) => Promise<any>;
};

// Custom hook to load a property by googlePlaceId
// Used for when we are browsing properties on the map
export const usePropertySummary = () => {
  const [property, setProperty] = useState<Property | null>(null);
  const [propertyLoading, setPropertyLoading] = useState(false);
  const [propertyError, setPropertyError] = useState(null);
  const [propertyErrorMessage, setPropertyErrorMessage] = useState('');

  const [sendCollaborationInvitationMutation] = useMutation(
    SEND_COLLABORATION_INVITATION,
  );

  // Utility function to confirm collaborator for the claimed property.
  const sendCollaborationInvitation = async ({
    propertyId,
    emailAddress,
    collaborationLabel,
    isAccountScope = false,
  }) =>
    sendCollaborationInvitationMutation({
      variables: {
        propertyId,
        emailAddress,
        collaborationLabel,
        isAccountScope,
      },
    });

  const [getPropertyQuery, { refetch }] = useLazyQuery(PROPERTY_SUMMARY, {
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (!data) return;
      setProperty(data?.property);
      setPropertyLoading(false);
      setPropertyError(null);
      setPropertyErrorMessage('');
    },
    onError: error => {
      let errorMsg = 'Sorry, we don’t have information on that spot.';
      const graphQLError = error?.graphQLErrors?.[0];
      if (graphQLError?.extensions?.name === ErrorNames.NonUsPropertyAddressError) {
        errorMsg = 'Currently, Digs Up only has information on US properties!';
      }

      setProperty(null);
      setPropertyLoading(false);
      setPropertyError(graphQLError);
      setPropertyErrorMessage(errorMsg);
    },
  });

  const getProperty = (googlePlaceId, subUnitType, subUnitIdentifier) => {
    if (!googlePlaceId) return;
    setPropertyLoading(true);
    getPropertyQuery({
      variables: {
        googlePlaceId,
        subUnitType,
        subUnitIdentifier,
      },
    });
  };

  const result: UsePropertySummaryResult = {
    refetch,
    getProperty,
    property,
    propertyLoading,
    propertyError,
    propertyErrorMessage,
    sendCollaborationInvitation,
  };

  return result;
};
