import { gql } from '@apollo/client';

export const SET_PROPERTY_VALUE = gql`
  mutation setPropertyValue(
    $accountPropertyId: ID!
    $fieldName: String!
    $fieldValue: String!
  ) {
    setPropertyValue(
      accountPropertyId: $accountPropertyId
      fieldName: $fieldName
      fieldValue: $fieldValue
    ) {
      id
      propertyDetails {
        id
        archStyle
      }
    }
  }
`;
