import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { PropertyRelationship } from '@digsup/constants';
import {
  REMOVE_COLLABORATOR,
  SEND_BETA_INVITATION,
  SEND_JOIN_ACCOUNT_INVITATION,
  REVOKE_JOIN_ACCOUNT_INVITATION,
  SEND_COLLABORATION_INVITATION,
  REVOKE_COLLABORATION_INVITATION,
} from '@/fe-graphql/mutations';

import { MY_ACCOUNT } from './gql';

export const useAccount = () => {
  const [accountById, setAccountById] = useState(null);
  const [getAccountByIdQuery] = useLazyQuery(MY_ACCOUNT, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data) {
        setAccountById(data.accountById);
      }
    },
  });

  const [removeCollaboratorMutation] = useMutation(REMOVE_COLLABORATOR);
  const [sendBetaInvitationMutation] = useMutation(SEND_BETA_INVITATION);
  const [sendJoinAccountInvitationMutation] = useMutation(
    SEND_JOIN_ACCOUNT_INVITATION,
  );
  const [revokeJoinAccountInvitationMutation] = useMutation(
    REVOKE_JOIN_ACCOUNT_INVITATION,
  );
  const [sendCollaborationInvitationMutation] = useMutation(
    SEND_COLLABORATION_INVITATION,
  );
  const [revokeCollaborationInvitationMutation] = useMutation(
    REVOKE_COLLABORATION_INVITATION,
  );

  const getAccountById = accountId => {
    if (accountId) {
      getAccountByIdQuery({
        variables: {
          id: accountId,
        },
      });
    }
  };

  const sendBetaInvitation = async emailAddress =>
    sendBetaInvitationMutation({
      variables: {
        emailAddress,
      },
    });

  const sendJoinAccountInvitation = async (accountId, accountInvitees) =>
    sendJoinAccountInvitationMutation({
      variables: {
        accountId,
        accountInvitees,
      },
      update(cache, res) {
        const joinAccountInvitations = res?.data?.sendJoinAccountInvitation;
        cache.modify({
          id: `Account:${accountId}`,
          fields: {
            accountInvitations(existingAccountInvitations) {
              return [...existingAccountInvitations, ...joinAccountInvitations];
            },
          },
        });
      },
    });

  // This is a variation for the above method used from dialogs where
  // we are just taking email addresses to invite users and not asking
  // for their relationship.
  const sendJoinAccountInvitationToEmails = async (
    accountId,
    emailAddresses,
  ) => {
    const accountInvitees = emailAddresses.map(emailAddress => ({
      emailAddress,
      relationshipLabel: PropertyRelationship.CO_OWNER,
    }));

    return sendJoinAccountInvitation(accountId, accountInvitees);
  };

  const revokeJoinAccountInvitation = async accountInvitationId =>
    revokeJoinAccountInvitationMutation({
      variables: {
        accountInvitationId,
      },
      update(cache, res) {
        const joinAccountInvitation = res?.data?.revokeJoinAccountInvitation;
        cache.modify({
          id: `Account:${joinAccountInvitation?.initiatedByAccountId}`,
          fields: {
            accountInvitations(existingAccountInvitations) {
              return existingAccountInvitations.filter(
                ai => ai.id === joinAccountInvitation.id,
              );
            },
          },
        });
      },
    });

  const removeMyCollaboration = async (accountPropertyId, collaboratorId) =>
    removeCollaboratorMutation({
      variables: {
        accountPropertyId,
        collaboratorId,
      },
      update(cache, res) {
        const collaborator = res?.data?.removeCollaborator;
        cache.modify({
          id: `AccountProperty:${collaborator.accountPropertyId}`,
          fields: {
            currentCollaborators(existingCurrentCollaborators) {
              return existingCurrentCollaborators.filter(
                c => c.id === collaborator.id,
              );
            },
          },
        });
      },
    });

  const removeCollaborator = async (accountPropertyId, collaboratorId) =>
    removeCollaboratorMutation({
      variables: {
        accountPropertyId,
        collaboratorId,
      },
      update(cache, res) {
        const collaborator = res?.data?.removeCollaborator;
        cache.modify({
          id: `AccountProperty:${collaborator.accountPropertyId}`,
          fields: {
            currentCollaborators(existingCurrentCollaborators) {
              return existingCurrentCollaborators.filter(
                c => c.id === collaborator.id,
              );
            },
          },
        });
      },
    });

  const sendCollaborationInvitation = async ({
    propertyId,
    emailAddress,
    collaborationLabel,
    isAccountScope = false,
  }) =>
    sendCollaborationInvitationMutation({
      variables: {
        propertyId,
        emailAddress,
        collaborationLabel,
        isAccountScope,
      },
      update(cache, res) {
        const collaborationInvitation = res?.data?.sendCollaborationInvitation;
        cache.modify({
          id: `AccountProperty:${collaborationInvitation.accountPropertyId}`,
          fields: {
            collaborationInvitations(existingCollaborationInvitations) {
              return [
                ...existingCollaborationInvitations,
                collaborationInvitation,
              ];
            },
          },
        });
      },
    });

  const revokeCollaborationInvitation = async collaborationInvitationId =>
    revokeCollaborationInvitationMutation({
      variables: {
        collaborationInvitationId,
      },
      update(cache, res) {
        const collaborationInvitation =
          res?.data?.revokeCollaborationInvitation;
        cache.modify({
          id: `AccountProperty:${collaborationInvitation.accountPropertyId}`,
          fields: {
            collaborationInvitations(existingCollaborationInvitations) {
              return existingCollaborationInvitations.filter(
                ci => ci.id === collaborationInvitation.id,
              );
            },
          },
        });
      },
    });

  return {
    accountById,
    getAccountById,
    removeCollaborator,
    removeMyCollaboration,
    sendBetaInvitation,
    sendJoinAccountInvitation,
    sendJoinAccountInvitationToEmails,
    revokeJoinAccountInvitation,
    sendCollaborationInvitation,
    revokeCollaborationInvitation,
  };
};
