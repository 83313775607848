import { gql } from '@apollo/client';
import { PROPERTY_FRAGMENTS } from '@/fe-graphql';

export const PROPERTY_USER_DATA = gql`
  query property($googlePlaceId: String, $propertyAddressSlugId: String) {
    property(
      googlePlaceId: $googlePlaceId
      propertyAddressSlugId: $propertyAddressSlugId
    ) {
      ...PropertyUserData
    }
  }
  ${PROPERTY_FRAGMENTS.propertyUserData}
`;
