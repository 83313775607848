import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  FINALIZE_CLAIM,
  INITIATE_CLAIM,
  CLAEANUP_UNFINAlIZED_CLAIMS,
  RELEASE_CLAIM_ON_PROPERTY,
  SET_ACCOUNT_PROPERTY_PREFERENCES,
  SET_CLAIMANT_LABEL,
  SET_PROPERTY_DESCRIPTION,
  SET_PROPERTY_VALUE,
  SET_PROPERTY_VALUES,
  ADD_PROPERTY_IMAGES,
  UPDATE_PROPERTY_IMAGE,
  DELETE_PROPERTY_IMAGE,
  VERIFY_OWNERSHIP,
} from '@/fe-graphql/mutations';

import { MY_ACCOUNT_PROPERTY } from './gql';

export const useAccountProperty = () => {
  const [accountPropertyByPropertyId, setAccountPropertyByPropertyId] =
    useState(null);
  const [getAccountPropertyByPropertyIdQuery] = useLazyQuery(
    MY_ACCOUNT_PROPERTY,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        if (data) {
          setAccountPropertyByPropertyId(data?.accountPropertyByPropertyId);
        }
      },
    },
  );

  const [cleanupUnfinalizedClaimsMutation] = useMutation(CLAEANUP_UNFINAlIZED_CLAIMS);
  const [initiateClaimMutation] = useMutation(INITIATE_CLAIM);
  const [finalizeClaimMutation] = useMutation(FINALIZE_CLAIM);
  const [releaseClaimOnPropertyMutation] = useMutation(
    RELEASE_CLAIM_ON_PROPERTY,
  );
  const [setAccountPropertyPreferencesMutation] = useMutation(
    SET_ACCOUNT_PROPERTY_PREFERENCES,
  );

  const [setPropertyValueMutation] = useMutation(SET_PROPERTY_VALUE);
  const [setPropertyValuesMutation] = useMutation(SET_PROPERTY_VALUES);
  const [setPropertyDescriptionMutation] = useMutation(
    SET_PROPERTY_DESCRIPTION,
  );

  const [setClaimantLabelMutation] = useMutation(SET_CLAIMANT_LABEL);

  const [addPropertyImagesMutation] = useMutation(ADD_PROPERTY_IMAGES);
  const [updatePropertyImageMutation] = useMutation(UPDATE_PROPERTY_IMAGE);
  const [deletePropertyImageMutation] = useMutation(DELETE_PROPERTY_IMAGE);
  const [verifyOwnershipMutation] = useMutation(VERIFY_OWNERSHIP);

  const getAccountProperty = propertyId => {
    if (propertyId) {
      getAccountPropertyByPropertyIdQuery({
        variables: {
          propertyId,
        },
      });
    }
  };

  const cleanupUnfinalizedClaims = async () =>
    cleanupUnfinalizedClaimsMutation().then(res => res?.data?.cleanupUnfinalizedClaims);


  const initiateClaim = async (
    propertyId: string,
    claimantLabel: string,
    subUnitType?: string,
  ) =>
    initiateClaimMutation({
      variables: {
        propertyId,
        claimantLabel,
        subUnitType,
      },
    }).then(res => res?.data?.initiateClaim?.id);

  const finalizeClaim = async ({ accountPropertyId }) =>
    finalizeClaimMutation({
      variables: {
        accountPropertyId,
      },
      update(cache, res) {
        const accountProperty = res?.data?.finalizeClaim;
        cache.modify({
          id: `PaginatedAccountProperties:${accountProperty.accountId}:claimed-properties`,
          fields: {
            count(existingCount) {
              return existingCount + 1;
            },
            rows(existingRows) {
              return [...existingRows, accountProperty];
            },
          },
        });
      },
    });

  const releaseClaimOnProperty = async (accountPropertyId: string, deleteData?: boolean) =>
    releaseClaimOnPropertyMutation({
      variables: {
        accountPropertyId,
        deleteData,
      },
      update(proxy, res) {
        const accountProperty = res?.data?.releaseClaimOnProperty;
        proxy.modify({
          id: `PaginatedAccountProperties:${accountProperty.accountId}:claimed-properties`,
          fields: {
            count(existingCount) {
              return existingCount - 1;
            },
            rows(existingRows, { readField }) {
              return existingRows.filter(
                row => accountProperty.id !== readField('id', row),
              );
            },
          },
        });
      },
    });

  type SetAccountPropertyPreferencesParams = {
    accountPropertyId: string;
    moveOutMinYears?: number;
    moveOutMaxYears?: number;
    allowMessageRequests: boolean;
  };

  const setAccountPropertyPreferences = async ({
    accountPropertyId,
    moveOutMinYears,
    moveOutMaxYears,
    allowMessageRequests,
  }: SetAccountPropertyPreferencesParams) =>
    setAccountPropertyPreferencesMutation({
      variables: {
        accountPropertyId,
        moveOutMinYears,
        moveOutMaxYears,
        allowMessageRequests,
      },
    });

  type SetClaimantLabelParams = {
    accountPropertyId: string;
    claimantLabel: string;
  };

  const setClaimantLabel = async ({
    accountPropertyId,
    claimantLabel,
  }: SetClaimantLabelParams) =>
    setClaimantLabelMutation({
      variables: {
        accountPropertyId,
        claimantLabel,
      },
    });

  const setPropertyValue = async (accountPropertyId, fieldName, fieldValue) =>
    setPropertyValueMutation({
      variables: {
        accountPropertyId,
        fieldName,
        fieldValue,
      },
    });

  const setPropertyValues = async (accountPropertyId, propertyFieldValues) =>
    setPropertyValuesMutation({
      variables: {
        accountPropertyId,
        propertyFieldValues,
      },
    });

  const setPropertyDescription = async ({ accountPropertyId, description }) =>
    setPropertyDescriptionMutation({
      variables: {
        accountPropertyId,
        description,
      },
    });

  // Utility function to add property-images to property
  const addPropertyImages = async (accountPropertyId, imageUrls) =>
    addPropertyImagesMutation({
      variables: {
        accountPropertyId,
        images: imageUrls,
      },
    });

  // Utility function to edit property-images
  const updatePropertyImage = async (accountPropertyId, image) => {
    await updatePropertyImageMutation({
      variables: {
        accountPropertyId,
        image,
      },
    });
  };

  // Utility function to delete property-images from property
  const deletePropertyImage = async (accountPropertyId, imageId) => {
    await deletePropertyImageMutation({
      variables: {
        accountPropertyId,
        imageId,
      },
    });
  };

  // Utility function to verify property ownership
  const verifyOwnership = async (accountPropertyId, verificationCode) => {
    return verifyOwnershipMutation({
      variables: {
        accountPropertyId,
        verificationCode,
      },
    });
  };

  return {
    accountProperty: accountPropertyByPropertyId,
    getAccountProperty,
    cleanupUnfinalizedClaims,
    initiateClaim,
    finalizeClaim,
    releaseClaimOnProperty,
    setAccountPropertyPreferences,
    setClaimantLabel,
    setPropertyDescription,
    setPropertyValue,
    setPropertyValues,
    addPropertyImages,
    updatePropertyImage,
    deletePropertyImage,
    verifyOwnership,
  };
};
