import { gql } from '@apollo/client';

export const REVOKE_JOIN_ACCOUNT_INVITATION = gql`
  mutation revokeJoinAccountInvitation($accountInvitationId: ID!) {
    revokeJoinAccountInvitation(accountInvitationId: $accountInvitationId) {
      id
      initiatedByAccountId
    }
  }
`;
