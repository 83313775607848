import { gql } from '@apollo/client';

export const CREATE_PAYMENT_INTENT = gql`
  mutation createPaymentIntent($licenseTemplateId: String!, $paymentMethodId: String, $referralCode: String) {
    createPaymentIntent(licenseTemplateId: $licenseTemplateId, paymentMethodId: $paymentMethodId, referralCode: $referralCode) {
      id
      clientSecret
    }
  }
`;
