import { gql } from '@apollo/client';

export const ADD_PROPERTY_BOOKMARK = gql`
  mutation addPropertyBookmark($propertyId: ID!) {
    addPropertyBookmark(propertyId: $propertyId) {
      id
      attributes
      property {
        propertyVersionAddressSlugId
        propertyUserData {
          id
          relationshipFlags {
            id
            isBookmarked
          }
        }
      }
    }
  }
`;
