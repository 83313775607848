import { gql } from '@apollo/client';

export const USER_BY_ID = gql`
  query userById($id: ID!) {
    userById(id: $id) {
      id
      firstName
      lastName
      email
      emailVerified
      profileBlurb
      avatarUrl
      createdAt
      updatedAt
      account {
        id
        currentLicense {
          id
          licenseTemplate {
            id
            displayName
            licenseImage
          }
        }
        claimedProperties {
          id
          count
          rows {
            id
            property {
              id
              createdAt
              propertyAddressSlugId
              propertyVersionAddressSlugId
              propertyInfo {
                id
                propertyName
                propertyFullName
                propertyCityState
                streetViewUrl
                satelliteViewUrl
              }
              propertyKpiData {
                currentPrice {
                  lowValue
                  highValue
                }
                moveOutInYears
                moveOutYearPrice {
                  lowValue
                  highValue
                }
                availability
                availabilityPrice {
                  lowValue
                  highValue
                }
                saleSpeed
                investmentRisk
              }
            }
          }
        }
        dreamHomes {
          id
          count
          rows {
            property {
              id
              createdAt
              propertyAddressSlugId
              propertyVersionAddressSlugId
              propertyInfo {
                id
                propertyName
                propertyFullName
                propertyCityState
                streetViewUrl
                satelliteViewUrl
              }
              propertyKpiData {
                currentPrice {
                  lowValue
                  highValue
                }
                moveOutInYears
                moveOutYearPrice {
                  lowValue
                  highValue
                }
                availability
                availabilityPrice {
                  lowValue
                  highValue
                }
                saleSpeed
                investmentRisk
              }
            }
          }
        }
        collaboratingOnProperties {
          id
          count
          rows {
            property {
              id
              createdAt
              propertyAddressSlugId
              propertyVersionAddressSlugId
              propertyInfo {
                id
                propertyName
                propertyFullName
                propertyCityState
                streetViewUrl
                satelliteViewUrl
              }
              propertyKpiData {
                currentPrice {
                  lowValue
                  highValue
                }
                moveOutInYears
                moveOutYearPrice {
                  lowValue
                  highValue
                }
                availability
                availabilityPrice {
                  lowValue
                  highValue
                }
                saleSpeed
                investmentRisk
              }
            }
          }
        }
      }
      settings {
        emailPreferences {
          missedNotifications
          featureAndServiceUpdates
          promoAndSpecialOffers
          newPropertyMatches
        }
        localizationSettings {
          timezone
          twelveHourFormat
          temperatureInCelsius
          changeTimezoneIgnored
        }
      }
    }
  }
`;
