import { gql } from '@apollo/client';

export const CHANGE_USER_EMAIL = gql`
  mutation changeUserEmail($userId: ID, $email: String!, $password: String!) {
    changeUserEmail(userId: $userId, email: $email, password: $password) {
      id
      email
      emailVerified
    }
  }
`;
