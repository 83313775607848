import { useMutation } from '@apollo/client';
import { useAppSelector, useAppDispatch } from '@/fe-common/redux';
import { CLEAR_NOTIFICATION, DISMISS_NOTIFICATION } from '@/fe-graphql/mutations';
import {
  markNotificationAsCleared,
  markNotificationAsShown,
} from '@/fe-common/redux/reducers/notification';

type ClearNotificationForUserParams = {
  notificationId: string;
  action: string;
  additionalData?: any;
};

export const useNotificationActions = () => {
  const dispatch = useAppDispatch();
  const [clearNotificationMutation] = useMutation(CLEAR_NOTIFICATION);
  const [dismissNotificationMutation] = useMutation(DISMISS_NOTIFICATION);
  const allNotifications = useAppSelector(state => state.notifications.allNotifications);

  const clearNotificationForUser = async ({
    notificationId,
    action,
    additionalData,
  }: ClearNotificationForUserParams) => {
    // Mark the notification as cleared in the notification context as well.
    const notification =
      Array.isArray(allNotifications) &&
      allNotifications?.find(n => n?.id === notificationId);
    if (notification) {
      dispatch(markNotificationAsCleared({ notificationId: notification.id }));
    }

    return clearNotificationMutation({
      variables: {
        notificationId,
        action,
        additionalData,
      },
    });
  };

  const dismissNotificationForUser = async (notificationId: string) => {
    // Mark the notification as shown in the notification context as well.
    const notification =
      Array.isArray(allNotifications) &&
      allNotifications?.find(n => n?.id === notificationId);
    if (notification) {
      dispatch(markNotificationAsShown({ notificationId: notification.id }));
    }

    return dismissNotificationMutation({
      variables: {
        notificationId,
      },
    });
  };

  return {
    clearNotificationForUser,
    dismissNotificationForUser,
  };
};
