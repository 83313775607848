import { gql } from '@apollo/client';

export const REMOVE_PROPERTY_BOOKMARK = gql`
  mutation removePropertyBookmark($propertyId: ID!) {
    removePropertyBookmark(propertyId: $propertyId) {
      id
      accountId
      propertyId
      attributes
      property {
        propertyVersionAddressSlugId
        propertyUserData {
          id
          relationshipFlags {
            id
            isBookmarked
          }
        }
      }
    }
  }
`;
