import { gql } from '@apollo/client';

export const SET_PROPERTY_VALUE = gql`
  mutation setPropertyValue(
    $accountPropertyId: ID!
    $fieldName: String!
    $fieldValue: String!
  ) {
    setPropertyValue(
      accountPropertyId: $accountPropertyId
      fieldName: $fieldName
      fieldValue: $fieldValue
    ) {
      id
      propertyDetails {
        id
        allStructuresLivingM2
        improvementYear
        bathsTotal
        bedrooms
        parkingType
        structureLevels
        taxYearAmount
        assdTotal
        coolingType
        flooringType
        heatingFuel
        heatingType
        lotSizeM2
        poolType
        poolFeatures
        structureType1
        structureOuterWalls
        structureYear
        latitude
        longitude
      }
    }
  }
`;
