import { useState } from 'react';
import { useMutation } from '@apollo/client';

import {
  CHECK_EMAIL_USED,
  CHECK_INVITATION_TOKEN_VALID,
  SIGNUP_USER,
  LOGIN_USER,
  SIGNOUT_USER,
  SEND_PASSWORD_RESET_EMAIL,
} from './gql';

export const useAuthentication = () => {
  const [invalidLoginAttempts, setInvalidLoginAttempts] = useState(0);
  const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

  const [checkEmailUsed] = useMutation(CHECK_EMAIL_USED);
  const [checkInvitationTokenValid] = useMutation(CHECK_INVITATION_TOKEN_VALID);
  const [logInUserMutation] = useMutation(LOGIN_USER);
  const [signOutUserMutation] = useMutation(SIGNOUT_USER);
  const [signUpUserMutation] = useMutation(SIGNUP_USER);
  const [sendPasswordResetEmailMutation] = useMutation(
    SEND_PASSWORD_RESET_EMAIL,
  );

  const logInUser = async variables =>
    logInUserMutation({ variables: { ...variables, timezone } }).catch(
      error => {
        setInvalidLoginAttempts(invalidLoginAttempts + 1);
        throw error;
      },
    );

  const signUpUser = async variables =>
    signUpUserMutation({ variables: { ...variables, timezone } });

  const signOutUser = async () => signOutUserMutation();

  const sendPasswordResetEmail = async variables =>
    sendPasswordResetEmailMutation(variables);

  return {
    invalidLoginAttempts,
    checkEmailUsed,
    checkInvitationTokenValid,
    logInUser,
    signOutUser,
    signUpUser,
    sendPasswordResetEmail,
  };
};
