import { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import isNull from 'lodash/isNull';
import omitBy from 'lodash/omitBy';

import {
  USER_BY_ID,
  UPDATE_USER,
  USER_BY_EMAIL,
  CHANGE_USER_EMAIL,
  SET_USER_SETTINGS,
} from './gql';

export const useUser = () => {
  const [userById, setUserById] = useState(null);
  const [userByEmail, setUserByEmail] = useState(null);
  const [getUserByIdQuery] = useLazyQuery(USER_BY_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data) {
        setUserById(data.userById);
      }
    },
  });
  const [getUserByEmailQuery] = useLazyQuery(USER_BY_EMAIL, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data) {
        setUserByEmail(data.userByEmail);
      }
    },
  });

  const [changeUserEmailMutation] = useMutation(CHANGE_USER_EMAIL);
  const [setUserSettingsMutation] = useMutation(SET_USER_SETTINGS);
  const [updateUserMutation] = useMutation(UPDATE_USER);

  const getUserById = userId => {
    if (userId) {
      getUserByIdQuery({
        variables: {
          id: userId,
        },
      });
    }
  };

  const getUserByEmail = email => {
    if (email) {
      getUserByEmailQuery({
        variables: {
          email: email,
        },
      });
    }
  };

  const changeUserEmail = async (email, password) =>
    changeUserEmailMutation({
      variables: {
        email,
        password,
      },
      update(proxy, res) {
        const userId = res.data.changeUserEmail.id;
        const userByIdQuery: any = proxy?.readQuery({
          query: USER_BY_ID,
          variables: {
            id: userId,
          },
        });
        proxy.writeQuery({
          query: USER_BY_ID,
          data: {
            userById: {
              ...userByIdQuery?.userById,
              ...res?.data?.changeUserEmail,
            },
          },
          variables: {
            id: userId,
          },
        });
      },
    });

  const setUserSettings = async (userId, updatedSettings, originalSettings) =>
    setUserSettingsMutation({
      variables: omitBy(updatedSettings, isNull),
      optimisticResponse: {
        __typename: 'Mutation',
        setUserSettings: {
          emailPreferences: {
            ...originalSettings?.emailPreferences,
            ...updatedSettings,
          },
          localizationSettings: {
            ...originalSettings?.localizationSettings,
            ...updatedSettings,
          },
        },
      },
      update(proxy, res) {
        const userByIdQuery: any = proxy?.readQuery({
          query: USER_BY_ID,
          variables: {
            id: userId,
          },
        });
        proxy.writeQuery({
          query: USER_BY_ID,
          data: {
            ...userByIdQuery,
            userById: {
              ...userByIdQuery?.userById,
              settings: {
                ...userByIdQuery?.userById?.settings,
                localizationSettings: {
                  ...userByIdQuery?.userById?.settings?.localizationSettings,
                  ...res?.data?.setUserSettings?.localizationSettings,
                },
                emailPreferences: {
                  ...userByIdQuery?.userById?.settings?.emailPreferences,
                  ...res?.data?.setUserSettings?.emailPreferences,
                },
              },
            },
          },
          variables: {
            id: userId,
          },
        });
      },
    });

  const updateUser = async (
    userId: string,
    updatedUserData: any,
    originalUserData: any = {},
  ) =>
    updateUserMutation({
      variables: {
        id: userId,
        ...updatedUserData,
      },
      optimisticResponse: originalUserData
        ? {
          __typename: 'Mutation',
          updateUser: {
            ...originalUserData,
            ...updatedUserData,
          },
        }
        : null,
    })

  return {
    userById,
    userByEmail,
    getUserById,
    getUserByEmail,
    changeUserEmail,
    setUserSettings,
    updateUser,
  };
};
