import { gql } from '@apollo/client';

export const SEND_JOIN_ACCOUNT_INVITATION = gql`
  mutation sendJoinAccountInvitation(
    $accountId: ID!
    $accountInvitees: [AccountInvitee]!
  ) {
    sendJoinAccountInvitation(
      accountId: $accountId
      accountInvitees: $accountInvitees
    ) {
      id
      invitedUserEmail
      relationshipLabel
      initiatedByAccountId
    }
  }
`;
