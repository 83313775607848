import { gql } from '@apollo/client';

export const VERIFY_OWNERSHIP = gql`
  mutation verifyOwnership(
    $accountPropertyId: ID!
    $verificationCode: String!
  ) {
    verifyOwnership(
      accountPropertyId: $accountPropertyId
      verificationCode: $verificationCode
    ) {
      id
      attributes
      claimantInfo
    }
  }
`;
