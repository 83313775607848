import { gql } from '@apollo/client';

export const CHECK_PROPERTY_PERMISSION = gql`
  mutation checkPropertyPermission($accountId: String, $propertyAddressSlugId: String!, $permissionName: String!) {
    checkPropertyPermission(accountId: $accountId, propertyAddressSlugId: $propertyAddressSlugId, permissionName: $permissionName) {
      permission
      hasPermission
      issues {
        type
        description
        action
      }
    }
  }
`;
