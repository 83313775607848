import { gql } from '@apollo/client';

export const INITIATE_CLAIM = gql`
  mutation initiateClaim(
    $propertyId: ID!
    $claimantLabel: String!
    $subUnitType: String
  ) {
    initiateClaim(
      propertyId: $propertyId
      claimantLabel: $claimantLabel
      subUnitType: $subUnitType
    ) {
      id
      accountId
      propertyId
      attributes
      claimantInfo
      property {
        id
        propertyAddressSlugId
        propertyVersionAddressSlugId
        createdAt
        propertyInfo {
          id
          propertyName
          propertyFullName
          propertyCityState
          streetViewUrl
          satelliteViewUrl
        }
      }
      currentCollaborators {
        id
        collaboratingUser {
          id
          firstName
          lastName
          email
          avatarUrl
        }
        collaborationLabel
      }
      collaborationInvitations {
        id
        invitedUserEmail
        collaborationLabel
      }
    }
  }
`;
