import { gql } from '@apollo/client';
import { ACCOUNT_FRAGMENTS } from '@/fe-graphql';

export const LOGIN_USER = gql`
  mutation logInUser(
    $username: String!
    $password: String!
    $timezone: String
    $facebookProfileId: String
    $twitterProfileId: String
    $googleProfileId: String
    $avatarUrl: String
  ) {
    logInUser(
      email: $username
      password: $password
      timezone: $timezone
      facebookProfileId: $facebookProfileId
      twitterProfileId: $twitterProfileId
      googleProfileId: $googleProfileId
      avatarUrl: $avatarUrl
    ) {
      token
      timezoneStatus
      onboardingCompleted
      user {
        id
        firstName
        lastName
        email
        emailVerified
        profileBlurb
        accountId
        settings {
          emailPreferences {
            missedNotifications
            featureAndServiceUpdates
            promoAndSpecialOffers
            newPropertyMatches
          }
          localizationSettings {
            timezone
            twelveHourFormat
            temperatureInCelsius
          }
        }
        account {
          id
          name
          ...OnboardingProgress
          searchProfiles {
            id
          }
        }
      }
    }
  }
  ${ACCOUNT_FRAGMENTS.onboardingProgress}
`;
