import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  ACCOUNT_PAYMENT_DATA,
  ACCOUNT_EXCESS_CLAIMS,
  ACCOUNT_SUMMARY
} from './gql';

export const useAccountSummary = accountId => {
  const [accountById, setAccountById] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [excessClaims, setExcessClaims] = useState([]);

  const [getAccountSummaryQuery] = useLazyQuery(ACCOUNT_SUMMARY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data) {
        setAccountById(data?.accountById);
      }
    },
  });

  const [getAccountPaymentDataQuery] = useLazyQuery(ACCOUNT_PAYMENT_DATA, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data) {
        const account = data?.accountById;
        setPaymentData(account);
      }
    },
  });

  const [getAccountExcessClaimsQuery] = useLazyQuery(ACCOUNT_EXCESS_CLAIMS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data) {
        const account = data?.accountById;
        setExcessClaims(account?.excessClaimedProperties);
      }
    },
  });

  const getAccountById = () => {
    if (accountId) {
      getAccountSummaryQuery({
        variables: {
          id: accountId,
        },
      });
    }
  };

  const getAccountPaymentData = () => {
    if (accountId) {
      getAccountPaymentDataQuery({
        variables: {
          id: accountId,
        },
      });
    }
  };

  const getAccountExcessClaims = () => {
    if (accountId) {
      getAccountExcessClaimsQuery({
        variables: {
          id: accountId,
        },
      });
    }
  };

  useEffect(() => {
    getAccountById();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    accountById,
    excessClaims,
    paymentData,
    getAccountById,
    getAccountPaymentData,
    getAccountExcessClaims,
  };
};
