import { gql } from '@apollo/client';

export const CHECK_ACCOUNT_PERMISSION = gql`
  mutation checkAccountPermission($accountId: String, $permissionName: String!) {
    checkAccountPermission(accountId: $accountId, permissionName: $permissionName) {
      permission
      hasPermission
      issues {
        type
        description
        action
      }
    }
  }
`;
