import { gql } from '@apollo/client';

export const ACCOUNT_EXCESS_CLAIMS = gql`
  query accountById($id: ID!) {
    accountById(id: $id) {
      id
      excessClaimedProperties
    }
  }
`;
