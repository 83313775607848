import { gql } from '@apollo/client';

export const REVOKE_COLLABORATION_INVITATION = gql`
  mutation revokeCollaborationInvitation($collaborationInvitationId: ID!) {
    revokeCollaborationInvitation(
      collaborationInvitationId: $collaborationInvitationId
    ) {
      id
      accountPropertyId
      initiatedByAccountId
    }
  }
`;
