import { gql } from '@apollo/client';

export const REQUEST_MESSAGE_THREAD = gql`
  mutation requestMessageThread(
    $propertyId: ID!
    $prospectIdentityAccountPropertyId: ID!
    $messageBody: String!
  ) {
    requestMessageThread(
      propertyId: $propertyId
      prospectIdentityAccountPropertyId: $prospectIdentityAccountPropertyId
      messageBody: $messageBody
    ) {
      id
    }
  }
`;
